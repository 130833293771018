import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  embedLink: string;

  constructor() { 
    this.embedLink = "https://twitter.com/debtduction?ref_src=twsrc%5Etfw"
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // @ts-ignore
    twttr.widgets.load();
  }

}
