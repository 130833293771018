<footer class="footer">
    <div class="footer-widgets">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <!-- Text widget-->
                    <aside class="widget widget-text">
                        <div class="widget-title">
                            <h6>Contact</h6>
                        </div>
                        <div class="textwidget">
                            <p>If you need any help, or have any questions please reach out to us via the email below.
                            </p>
                            <p>
                                E-mail: support@debtduction.com
                            </p>
                            <ul class="social-icons" id="social">
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                    </aside>
                </div>
                <div class="col-md-3">
                    <!-- Recent entries widget-->
                    <aside class="widget widget-recent-entries">
                        <div class="widget-title">
                            <h6>Recent Blog Posts</h6>
                        </div>
                        <ul style="padding-inline-start: 0px !important;">
                            <li><a href="#">Placeholder 1</a><span class="post-date">May 8, 2020</span></li>
                            <li><a href="#">Placeholder 2</a><span class="post-date">April 7, 2020</span></li>
                            <li><a href="#">placeholder 3</a><span class="post-date">September 7, 2020</span></li>
                        </ul>
                    </aside>
                </div>
                <div class="col-md-3">
                    <!-- Twitter widget-->
                    <aside class="widget twitter-feed-widget">
                        <div class="widget-title">
                            <h6>Twitter Feed</h6>
                        </div>
                        <div class="twitter-feed">
                            <a class="twitter-timeline" data-width="220" data-height="250" data-theme="dark"
                                data-chrome="nofooter noheader" href="{{embedLink}}">Tweets by debtduction</a>
                        </div>
                    </aside>
                </div>
                <div class="col-md-3">
                    <!-- Recent works-->
                    <aside class="widget widget-recent-works">
                        <div class="widget-title">
                            <h6>Instagram</h6>
                        </div>
                        <ul style="padding-inline-start: 0px !important;">
                            <li><a href="#"><img src="assets/images/widgets/1.jpg" alt=""></a></li>
                            <li><a href="#"><img src="assets/images/widgets/2.jpg" alt=""></a></li>
                            <li><a href="#"><img src="assets/images/widgets/3.jpg" alt=""></a></li>
                            <li><a href="#"><img src="assets/images/widgets/7.jpg" alt=""></a></li>
                            <li><a href="#"><img src="assets/images/widgets/8.jpg" alt=""></a></li>
                            <li><a href="#"><img src="assets/images/widgets/6.jpg" alt=""></a></li>
                        </ul>
                    </aside>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bar">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="copyright textwidget">
                        <p>© 2020 Debtduction LLC. <a class="text-center footer-link" href="#privacy-policy">Privacy
                                Policy</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
