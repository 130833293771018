// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDVbXa8i8KeryMBL9PfYAbh38epxcMNibw",
    authDomain: "debtductino-waitlist.firebaseapp.com",
    databaseURL: "https://debtductino-waitlist.firebaseio.com",
    projectId: "debtductino-waitlist",
    storageBucket: "debtductino-waitlist.appspot.com",
    messagingSenderId: "956522869278",
    appId: "1:956522869278:web:32e966611afbfc322bb40f",
    measurementId: "G-Y6L7LD0W50"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
