import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FooterComponent } from './components/footer/footer.component';



@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    CommonModule,
    NgbPaginationModule,
    FontAwesomeModule
  ],
  exports: [
    CommonModule,
    NgbPaginationModule,
    FooterComponent,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class SharedModule { }
