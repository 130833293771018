import { Component, HostListener } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';
import { filter } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms'
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from './shared/services/auth.service';

export interface Email { email: string }
//declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Debtduction';
  faCoffee = faCoffee;
  alert: boolean = false;
  _db: AngularFirestore;
  email: Observable<any[]>;
  submitted = false;
  userEmail = new FormGroup({
    primaryEmail: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
    ])
  })
  loginEmail: string;
  password: string;


  constructor(public afAuth: AngularFireAuth, db: AngularFirestore, private router: Router, private gtmService: GoogleTagManagerService, public authService: AuthService) {

    this.afAuth.signInAnonymously();
    this.email = db.collection('waitlistMembers').valueChanges();
    this._db = db;
  }

  addEmail(aEmail: string) {

    let d = new Date();
    var signupDate = d.toLocaleDateString();
    var signupTime = d.toLocaleTimeString();

    this.submitted = true;
    if (this.userEmail.invalid) {
      return;
    }

    let signupMap = {
      email: aEmail,
      date: signupDate,
      time: signupTime
    }
    let setDoc = this._db.collection('waitlistMembers').doc(aEmail).set(signupMap);

    this.alert = true
    // this.googleAnalyticsService.eventEmitter("SIGN UP!", "Sign up", "Sign up!", "Sign up", 10);
    alert('Congrats! Your email has been added to our waitlist!')
  }


  closeAlert() {
    this.alert = false
  }

  get primEmail() {
    return this.userEmail.get('primaryEmail')
  }

  customEvent() {

    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'button-click',
      data: 'my-custom-event',
    };
    this.gtmService.pushTag(gtmTag);

    alert('this is a custom event');
  }

  /* @HostListener('window:scroll', ['$event'])

  onWindowScroll(e) {
    let element = document.querySelector('.header');
    let element2 = document.querySelector('.topSignUp');
    let img = document.getElementById('navbar-brand') as HTMLImageElement;

    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('header-small');
      element.classList.add('header-shadow');
      element.classList.add('header-scrolled');
      element2.classList.add('topSignUp-Scrolled');
      img.src = './assets/images/logo_small_blue.png';

    } else {
      element.classList.remove('header-small');
      element.classList.remove('header-shadow');
      element.classList.remove('header-scrolled');
      element.classList.remove('dropdown-color');
      element2.classList.remove('topSignUp-Scrolled')
      img.src = './assets/images/navbar_logo_white.png'
    }
  } */

  ngOnInit() {
    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  /* 
    SignUp() {
      this.authService.SignUp(this.loginEmail, this.password);
      this.loginEmail = this.password = '';
    }
  
    SignIn() {
      this.authService.SignIn(this.loginEmail, this.password);
      this.loginEmail = this.password = '';    
    }
  
    SignOut() {
      this.authService.SignOut();
    } */
}
