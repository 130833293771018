import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { concat, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  // Save logged in user data
  userData: any;
  fName: string;
  lName: string;
  credentialData: any;
 // accessToken: any;

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        //this.accessToken = this.getCredentialData().accessToken
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }


  // Sign out 
  SignOut() { 
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    })
  }

  // Sign in with email/password
  async SignIn(email: string, password: string) {
    var result = await this.afAuth.signInWithEmailAndPassword(email, password)
    this.SetUserData(result.user);
    if (result.user.emailVerified !== true) {
      this.SendVerificationMail();
      window.alert('Please validate your email address. Kindly check your inbox.');
    } 
    else {
      this.userData = result.user;
      //this.accessToken = this.getCredentialData().accessToken
      this.credentialData = result.credential;
      this.router.navigateByUrl('dashboard');
    }
  }

  // Sign up with email/password
  SignUp(email: string, password: string, firstName: string, lastName: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
        this.SetUserData(result.user);
        result.user.updateProfile({
          displayName: firstName + ' ' + lastName
        })
      }).catch((error) => {
        window.alert(error.message)
      })
  }


  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
    .then(() => {
      this.router.navigate(['verify-email']);
    })
  }

  // Reset Forgot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    localStorage.setItem('user', JSON.stringify(this.userData));
    //console.log('credentials' + this.credentialData);
    console.log(localStorage);
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    return (user !== null && user.emailVerified != false);
  }

  // Sign in with Google
  async GoogleAuth() {
    await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
    this.router.navigate(['dashboard']);
  }

  // Sign in with Facebook
  async FacebookAuth() {
    await this.afAuth.signInWithPopup(new auth.FacebookAuthProvider())
    this.router.navigate(['dashboard']);
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
        this.SetUserData(result.user);
        console.log(result);
        console.log(result.credential);
        this.credentialData = result.credential;
      }).catch((error) => {
        window.alert(error)
      })
  }

  getUserData(){
    return this.userData;
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      displayName: user.displayName
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  getCredentialData() {
    console.log(this.credentialData);
    return this.credentialData;
  }

  emailIsVerified() {
    const user = JSON.parse(localStorage.getItem('user'));
    let emailStatus = this.userData.emailVerified;
    return emailStatus
  }
}