import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthService } from './shared/services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { SharedModule } from './shared/shared.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(
      environment.firebase,
      'Debtduction-Waitlist'
    ),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    AngularFireFunctionsModule,
    SharedModule
  ],
  providers: [
    /* GoogleAnalyticsService, */
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: 'googleTagManagerId', useValue: 'GTM-P9H92ZC' },
    { provide: REGION, useValue: 'us-central1' },
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
